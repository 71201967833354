
import Vue from "vue"
import ClickOutside from "vue-click-outside"
import { getImagesAsset, getDemoAsset, checkDomain } from "@/config/util"
import subscriptionStatusMixin from "@/mixins/subscriptionStatusMixin"
import SvgIcon from "@/components/base/SvgIcon/SvgIcon.vue"
import BlockPopupInvite from "@/components/blocks/BlockPopup/BlockPopupInvite.vue"
import { mapState } from "vuex"
import notificationSoundFile from "@/assets/sound/new-message.mp3"

interface Data {
  placeholderImage: string
  notificationImage: string
  notificationNum: number
  active: boolean
  pageCheck: boolean
  showPopup: boolean
  ExplanationVideoPopupActive: boolean
  prevNotificationCount: number | null
  notificationSound: HTMLAudioElement | null
  initialLoadComplete: boolean
  showWarningAlert: boolean
  showDangerAlert: boolean
}

export default Vue.extend({
  name: "NotificationComponent",

  components: {
    SvgIcon,
    BlockPopupInvite,
  },

  directives: {
    ClickOutside,
  },
  mixins: [subscriptionStatusMixin],

  data(): Data {
    return {
      placeholderImage: getImagesAsset("placeholders/user_placeholder.png"),
      notificationImage: getDemoAsset("profile/notification.png"),
      notificationNum: 17,
      active: false,
      pageCheck: false,
      showPopup: false,
      ExplanationVideoPopupActive: false,
      prevNotificationCount: null, // Initialize as null to prevent unintended comparisons
      notificationSound: null,
      initialLoadComplete: false, // Flag to indicate initial load completion
      showWarningAlert: false,
      showDangerAlert: false,
    }
  },

  computed: {
    isTrialing() {
      return this.$store.getters.subscriptionIsTrial
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    image() {
      return this.$store.getters.getProfileImage
    },
    subscription() {
      return this.$store.state.Broker.subscription
    },
    subscriptionName() {
      let plan = this.$store.state.Broker.subscription
      if (this.isActiveSubscription(plan?.status)) {
        return plan?.name
      } else if (this.isTrialingSubscription(plan?.status)) {
        return `${plan?.name} - Free`
      } else {
        return "Free tier"
      }
    },
    daysRemaining() {
      const today = new Date()
      const endsAt = new Date(this.subscription?.ends_at)

      const diff = endsAt.getTime() - today.getTime()
      const days = Math.round(diff / (1000 * 3600 * 24))

      return days > 1 ? `${days} days` : `${days} day`
    },
    explanationVideoFromhamburgerMenuClicked() {
      return this.$store.getters.getExplanationVideoClicked
    },
    userRoleSpecificVideoLink() {
      return this.$store.getters.getUser.role === "broker"
        ? "https://financelobby-production.s3.amazonaws.com/assets/Broker.mp4"
        : "https://financelobby-production.s3.amazonaws.com/assets/FinanceLobby051222.mp4"
    },

    userRoleSpecificMessage() {
      const responseCategory = this.getAvarageResponseTime.label

      // Broker-specific messages
      if (this.$store.getters.getUser.role === "broker") {
        if (responseCategory === "A few business days") {
          return `
        <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Medium </h3>
        <p class="text-[14px] text-[##B45309] font-medium">Your responsiveness has dropped, which will reduce deal visibility and lender interest. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
      `
        }

        if (responseCategory === "A week or more") {
          return `
        <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Low</h3>
        <p class="text-[14px] text-[##B45309] font-medium">Low responsiveness will negatively affect your deal visibility and lead lenders to prioritize more responsive brokers. Prompt replies help improve your overall response time, so be sure to respond to messages quickly. </p>
      `
        }
      }

      // Lender-specific messages
      if (this.$store.getters.getUser.role === "lender") {
        if (responseCategory === "A few business days") {
          return `
        <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Medium </h3>
        <p class="text-[14px] text-[##B45309] font-medium">Your responsiveness has dropped, which will reduce broker interest and the likelihood of your quotes being accepted. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
      `
        }

        if (responseCategory === "A week or more") {
          return `
        <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Low </h3>
        <p class="text-[14px] text-[##B45309] font-medium">Low responsiveness will negatively affect broker interest and reduce the likelihood of your quotes being accepted. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
      `
        }
      }

      // Default empty message
      return ""
    },

    userRoleSpecificMessageVariant() {
      const responseCategory = this.getAvarageResponseTime.label

      if (responseCategory === "A few business days") {
        console.log("Setting variant to warning") // Debugging
        return "warning"
      }

      if (responseCategory === "A week or more") {
        console.log("Setting variant to danger") // Debugging
        return "warning"
      }

      return ""
    },

    ...mapState({
      non_beta_user: (state) => state.User.non_beta_user,
      unseenMessages: (state) => state.Chat.chat.unseenMessages,
    }),
    getPlayNotificationSound() {
      return this.$store.getters.getPlayNotificationSound
    },
    getAvarageResponseTime() {
      const seconds = this.$store.getters.getAvarageResponseTime

      // Handle cases where the response time is null or undefined
      if (seconds == null || seconds === 0) {
        return { time: "No response time recorded", label: "Not available" }
      }

      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      let time, label

      if (days > 0) {
        time = `${days} day${days > 1 ? "s" : ""} ago`
      } else if (hours > 0) {
        time = `${hours} hour${hours > 1 ? "s" : ""} ago`
      } else if (minutes > 0) {
        time = `${minutes} minute${minutes > 1 ? "s" : ""} ago`
      } else {
        time = `a few seconds ago`
      }

      if (seconds > 86400) {
        label = "A week or more"
      } else if (seconds > 28800) {
        label = "A few business days"
      } else if (seconds > 14400) {
        label = "A business day"
      } else {
        label = "A few business hours"
      }

      return { time, label }
    },

    responseLabelClass() {
      const label = this.getAvarageResponseTime.label

      switch (label) {
        case "A few business hours":
        case "A business day":
          return "response-label-green"
        case "A few business days":
          return "response-label-yellow"
        case "A week or more":
          return "response-label-red"
        default:
          return ""
      }
    },
  },

  watch: {
    getPlayNotificationSound(val: boolean) {
      if (val) {
        this.$store.dispatch("playNotificationSound", false)
        this.playNotificationSound()
      }
    },
    unseenMessages: {
      handler(newVal: { count: number }) {
        console.log("Watcher triggered: unseenMessages changed to", newVal)
        if (!this.initialLoadComplete) {
          // Do not play sound or send notification on initial load
          console.log("Initial load detected. Sound and notification will not play.")
        } else if (newVal.count > (this.prevNotificationCount || 0)) {
          console.log("New unseen messages detected. Playing sound and sending notification...")
          this.playNotificationSound()
          this.sendBrowserNotification("New Message", {
            body: `You have ${newVal.count} new message(s).`,
            icon: this.notificationImage, // Optional: Add an icon
            // You can add more options like `vibrate` or `tag` if needed
          })
        }
        this.prevNotificationCount = newVal.count
        this.updateDocumentTitle(newVal)
      },
      deep: true,
      immediate: true, // Keep immediate to handle updates promptly
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("getUnseenMessages").then(() => {
        this.prevNotificationCount = this.unseenMessages.count
        console.log("Initial unseenMessages count:", this.prevNotificationCount)
        this.initialLoadComplete = true // Set flag after initial load
      })

      // Load average response time
      var auth = JSON.parse(localStorage.getItem("auth"))

      this.loadAvarageResponse(auth?.user?.id)

      const firstTimeLogin = localStorage.getItem("second_time_login")

      if (!firstTimeLogin) {
        document.cookie.split(";").forEach((cookie) => {
          const name = cookie.split("=")[0].trim()

          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
        })

        localStorage.clear()

        localStorage.setItem("second_time_login", "true")

        window.location.assign('/login')
      }
    }
    this.showDangerAlert = !localStorage.getItem("dismissedDangerAlert")
    this.showWarningAlert = !localStorage.getItem("dismissedWarningAlert")

    // Initialize notification sound
    this.notificationSound = new Audio(notificationSoundFile)
    this.notificationSound.preload = "auto"

    // Request notification permission
    this.requestNotificationPermission()

    // Listen for logout event
    this.$root.$on("logout-beta", this.logout)
  },

  beforeDestroy() {
    this.$root.$off("logout-beta", this.logout)
  },

  methods: {
    async loadAvarageResponse(id) {
      try {
        const response = await this.$store.dispatch("useAvarageResponseTime", id)
        console.log("Average response time:", response)
      } catch (error) {
        console.error("Error loading average response time:", error)
      }
    },

    dismissAlert(type) {
      if (type === "danger") {
        this.showDangerAlert = false
        localStorage.setItem("dismissedDangerAlert", "true")
      } else if (type === "warning") {
        this.showWarningAlert = false
        localStorage.setItem("dismissedWarningAlert", "true")
      }
    },

    async handleManageMySubscription() {
      try {
        const response = await this.$store.dispatch("getSparkUrl", {
          return_url: `${window.location.origin}${window.location.pathname}`,
        })
        window.location.href = response.data.data.sparkLogin.success
      } catch (error) {
        console.error("Error managing subscription:", error)
      }
    },

    playNotificationSound() {
      const currentPath = this.$route.path
      const excludedPaths = ["/lender-messages", "/broker-messages"]

      if (!this.active && !excludedPaths.includes(currentPath) && this.notificationSound) {
        this.notificationSound
          .play()
          .then(() => {
            console.log("Notification sound played successfully.")
          })
          .catch((error) => {
            console.error("Sound play failed:", error)
          })
      } else {
        console.log(`Sound not played. Current path: ${currentPath}`)
      }
    },

    clickOutside(event: Event) {
      if (this.$refs.video && !(this.$refs.video as HTMLElement).contains(event.target as Node)) {
        this.ExplanationVideoPopupActive = false
      }
    },

    ExplanationVideo() {
      this.ExplanationVideoPopupActive = true
      this.$store.dispatch("explanationVideoFromhamburgerMenuClicked", false)
    },

    popupClose() {
      this.ExplanationVideoPopupActive = false
      this.$store.dispatch("explanationVideoFromhamburgerMenuClicked", false)
    },

    logout() {
      this.$store.dispatch("logout").then(() => {
        if (process.env.VUE_APP_GTM_ENVIRONMENT === "production") {
          //Google Analytics userID
          var auth = JSON.parse(localStorage.getItem("auth"))

          window["dataLayer"] = window["dataLayer"] || []
          window["dataLayer"].push({
            event: "logout",
            userId: auth?.user?.id,
          })
        }

        // this.$store.commit('INCREMENT_UNSEEN_MESSAGES', {})
        localStorage.removeItem("auth")
        localStorage.removeItem("dismissedWarningAlert")
        localStorage.removeItem("dismissedDangerAlert")
        localStorage.removeItem("chatbot")
        localStorage.removeItem("ai-state")
        window.location.href = "/login"
      })
    },

    hide() {
      this.active = false
    },

    clearToken() {
      if (this.$router.currentRoute.path === "/sign-up/lender/2") {
        const token = localStorage.getItem("tempToken")
        if (token !== null) {
          localStorage.removeItem("tempToken")
        }
      }
    },

    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
    goTo() {
      if (this.$route.path !== "/profile") {
        this.$router.push({ path: "/profile" })
      }
    },

    logoChange(): boolean {
      return checkDomain()
    },

    updateDocumentTitle(unseenMessages: { count: number }) {
      const count = unseenMessages?.count || 0
      console.log("Updating document title with count:", count)
      if (count > 0) {
        document.title = `(${count}) New Messages`
        this.changeFavicon("/faviconnotification.ico")
      } else {
        document.title = "Finance Lobby - Meet Your Perfect Deal"
        this.changeFavicon("/favicon.ico")
      }
    },
    changeFavicon(href: string) {
      let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
      if (link) {
        link.href = `${href}?v=${new Date().getTime()}`
      } else {
        link = document.createElement("link")
        link.rel = "shortcut icon"
        link.href = `${href}?v=${new Date().getTime()}`
        document.head.appendChild(link)
      }
    },

    /**
     * Requests permission from the user to send browser notifications.
     */
    requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.error("This browser does not support desktop notifications.")
        return
      }

      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.")
          } else if (permission === "denied") {
            console.log("Notification permission denied.")
          }
          // No action needed if permission is already granted or denied
        })
      }
    },

    /**
     * Sends a browser notification with the given title and options.
     * @param title The title of the notification.
     * @param options Optional notification options such as body, icon, etc.
     */
    // eslint-disable-next-line no-undef
    sendBrowserNotification(title: string, options?: NotificationOptions) {
      if (Notification.permission === "granted") {
        const notification = new Notification(title, options)
        notification.onclick = () => {
          window.focus()
          this.$router.push("/chat") // Adjust the path as needed
          notification.close()
        }
      } else {
        console.warn("Notification permission not granted.")
      }
    },
  },
})
